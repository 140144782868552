//  Libs
import React, { useContext, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Context
import { HeaderContext } from '../../../../context/header';
import { FooterContext } from '../../../../context/footer';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';

import { device } from 'components/device';
import BreadCrumb from 'components/breadCrumb';
import Menu from 'components/contactUs/menu';
import DescriptionPage from 'components/contactUs/descriptionPage';
import Contact from 'components/contactUs/contact';
import HeaderIntern from 'components/contactUs/headerIntern';

// Styles
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  h1 {
    width: 100%;
  }

  p {
    color: #8f9194;
  }

  code {
    margin-top: 1.375rem;
    margin-left: 2.8125rem;
    color: #8f9194;
    font-size: unset;
    font-family: unset;
    background: unset;
  }

  @media ${device.laptop} {
    display: block;
    overflow-x: hidden;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const ContainerContent = styled.div`
  display: inline-grid;
  grid-column: 5 / 11;
  flex-direction: column;

  a {
    display: inline-flex;
    color: #45a7df;
  }

  section {
    p {
      img {
        :nth-child(2n + 1) {
          position: relative;
          top: 1.25rem;
        }
      }
    }

    p:last-child {
      margin-bottom: 0;

      img {
        position: relative;
        top: 3.5rem;
      }
    }

    p:first-child {
      a {
        padding-bottom: 10px;
      }
    }
  }

  @media ${device.laptop} {
    padding: 0 20px;
    display: flex;

    p:nth-child(2n + 1) {
      line-height: 1.5rem;
    }
  }

  @media ${device.mobile320} {
    padding: 0 20px;
    display: flex;

    p {
      width: 100%;
      font-size: 0.74rem;
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const ComplimentsAndSuggestions = ({ page, location }) => {
  const { setHasDefaultHeader, setCallCenterBackLink } = useContext(
    HeaderContext
  );

  const { setHasFooter } = useContext(FooterContext);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setHasDefaultHeader(false);
      setCallCenterBackLink('/fale-conosco/elogio-ou-reclamacao');
      setHasFooter(false);
    }
  }, []);

  const pathname = location.pathname;

  return (
    <Layout>
      <HeaderIntern />
      <SEO dataSeo={page} />
      <GridContainer>
        <Wrapper>
          <BreadCrumb
            markdown={getMarkdown(page, 'ckiadr2qo2ebm0973u5uzp365', true)}
            marginTop
          />
        </Wrapper>
        <ContainerContent>
          <DescriptionPage
            markdown={getMarkdown(page, 'ckiadukig2e7s0962m6g0hi97', true)}
            widthLarge
          />
          <Contact
            markdown={getMarkdown(page, 'ckiae24w02f8p0973g2idyqfs', true)}
          />
          <Menu intern pathname={pathname} />
        </ContainerContent>
      </GridContainer>
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckiadp8ds2e6f0973cf7a1w7x" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <ComplimentsAndSuggestions
            page={response.gcms.site.pages}
            location={location}
          />
        );
      }}
    />
  );
};
